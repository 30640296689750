<template>
  <div>
    <b-card header-bg-variant="primary">
      <template slot="header"> Veri modelleriyle test işlemi </template>
      <b-row>
        <b-col cols="2">
          <b-button variant="success" @click="TestSpeedWithWisdomDataModals()">testi başlat</b-button>
        </b-col>
        <!--         <b-col cols="2"> Kullanıcı Sayısı: </b-col>
        <b-col cols="2">
          <b-form-select v-model="user_count">
            <option v-for="(x, x_ind) in 10" :value="x">{{ x }}</option>
          </b-form-select>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="6"><strong>Beklenen</strong></b-col>
        <b-col cols="6"><strong>Sonuç</strong></b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-row>
            <b-col cols="6">Database İşlem Süresi (ms)</b-col>
            <b-col cols="6">{{ speed_data_with_wisdom_data_models.dif_database_start_end_expected.min + ' - ' + speed_data_with_wisdom_data_models.dif_database_start_end_expected.max }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Network Süresi (ms)</b-col>
            <b-col cols="6"> {{ speed_data_with_wisdom_data_models.network_time_expected.min + ' - ' + speed_data_with_wisdom_data_models.network_time_expected.max }} </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Toplam Süre (ms)</b-col>
            <b-col cols="6"> {{ speed_data_with_wisdom_data_models.dif_service_start_end_expected.min + ' - ' + speed_data_with_wisdom_data_models.dif_service_start_end_expected.max }} </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <!--           <b-row>
            <b-col cols="6">Servis Başlama Zamanı</b-col>
            <b-col cols="6">{{ speed_data_with_wisdom_data_models.starting_time }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Servis Bitiş Zamanı</b-col>
            <b-col cols="6">{{ speed_data_with_wisdom_data_models.ending_time }}</b-col>
          </b-row> -->
          <b-row>
            <b-col cols="6">Database İşlem Süresi (ms)</b-col>
            <b-col cols="6" v-if="speed_data_with_wisdom_data_models.dif_database_start_end">
              <span :style="speed_data_with_wisdom_data_models.dif_database_start_end_expected.max - speed_data_with_wisdom_data_models.dif_database_start_end > 0 ? 'color: green' : 'color: red'">{{ speed_data_with_wisdom_data_models.dif_database_start_end }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Network Süresi (ms)</b-col>
            <b-col cols="6" v-if="speed_data_with_wisdom_data_models.network_time">
              <span :style="speed_data_with_wisdom_data_models.network_time_expected.max - speed_data_with_wisdom_data_models.network_time > 0 ? 'color: green' : 'color: red'">{{ speed_data_with_wisdom_data_models.network_time }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Toplam Süre (ms)</b-col>
            <b-col cols="6" v-if="speed_data_with_wisdom_data_models.dif_service_start_end">
              <span :style="speed_data_with_wisdom_data_models.dif_service_start_end_expected.max - speed_data_with_wisdom_data_models.dif_service_start_end > 0 ? 'color: green' : 'color: red'">{{ speed_data_with_wisdom_data_models.dif_service_start_end }}</span>
            </b-col>
          </b-row>
          <!--           <b-row>
            <b-col cols="6">Database analiz başlama zamanı</b-col>
            <b-col cols="6">{{ speed_data_with_wisdom_data_models.database_start }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="6">Database analiz bitiş zamanı</b-col>
            <b-col cols="6">{{ speed_data_with_wisdom_data_models.database_end }}</b-col>
          </b-row> -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TestService from '@/services/test';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'SpeedTest',
  components: {
    Modal
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      user_count: 1,
      user: {},
      speed_data_with_wisdom_data_models: {
        'starting_time': '',
        'ending_time': '',
        'database_start': '',
        'database_end': '',
        'dif_service_start_end': '',
        'dif_database_start_end': '',
        'network_time': '',
        'dif_service_start_end_expected': {
          'min': 0,
          'max': 10000
        },
        'network_time_expected': {
          'min': 0,
          'max': 5000
        },
        'dif_database_start_end_expected': {
          'min': 0,
          'max': 5000
        }
      }
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    // this.TestSpeedWithWisdomDataModals();
  },
  methods: {
    TestSpeedWithWisdomDataModals: function () {
      for (let i = 0; i < parseInt(this.user_count); i++) {
        this.speed_data_with_wisdom_data_models.starting_time = new Date().toISOString();
        TestService.SpeedWithDataModals()
          .then(resp => {
            // console.log(resp.data);
            if (resp.data.status === 'success') {
              this.speed_data_with_wisdom_data_models.database_start = resp.data.result.database_start;
              this.speed_data_with_wisdom_data_models.database_end = resp.data.result.database_end;
            }
            this.speed_data_with_wisdom_data_models.ending_time = new Date().toISOString();
            let service_start = new Date(this.speed_data_with_wisdom_data_models.starting_time);
            let service_start_Msec = service_start.getTime();
            let service_end = new Date(this.speed_data_with_wisdom_data_models.ending_time);
            let service_end_Msec = service_end.getTime();
            this.speed_data_with_wisdom_data_models.dif_service_start_end = service_end_Msec - service_start_Msec;
            let database_start = new Date(this.speed_data_with_wisdom_data_models.database_start);
            let database_start_Msec = database_start.getTime();
            let database_end = new Date(this.speed_data_with_wisdom_data_models.database_end);
            let database_end_Msec = database_end.getTime();
            this.speed_data_with_wisdom_data_models.dif_database_start_end = database_end_Msec - database_start_Msec;
            this.speed_data_with_wisdom_data_models.network_time = this.speed_data_with_wisdom_data_models.dif_service_start_end - this.speed_data_with_wisdom_data_models.dif_database_start_end;
            this.$forceUpdate();
          });
      }
    }
  },
  watch: {}
}

</script>

