import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  SpeedWithDataModals () {
    return Vue.http.get(API_BASE_URL + 'test/SpeedWithDataModals');
  },
  Speed () {
    return Vue.http.get(API_BASE_URL + 'test/Speed');
  }
};
